import { useEffect, useState } from "react";

import { CabInput } from "@CabComponents/CabInput";
import { CabAvatar, CabExecPicker, CabIcon, CabModal } from "@CabComponents/index";
import { Box, styled, Typography, useMediaQuery } from "@mui/material";
import { GridCallbackDetails, GridFilterModel, GridSortModel } from "@mui/x-data-grid-pro";
import { IoDownloadOutline } from "react-icons/io5";
import { DateTime } from "luxon";
import { ChangeEvent, useMemo } from "react";
import colors from "../../colors";
import {
  ConsolidatedLocation, EventCategory, Leader,
  EventType, ExecutiveContact, User, EventAnalyticsState, AnalyticsEventsMeta
} from "../../store";
import ReconciliationDatePicker
  from "../../components/EventAnalytics/ReconciliationDatePicker/ReconciliationDatePicker";
import ReconciliationGrid, { durationValueGetter } from "./ReconciliationGrid";
import { CSVLink } from "react-csv";
import AnalyticsTab from "../Settings/AnalyticsTab/AnalyticsTab";
import { getLeaderIconSrc } from "../../utils/leaderUtils";
import CabSpinner from "@CabComponents/CabSpinner";
import { LeaderEvent } from "../../store/cabinetApi/generated/analytics";


const tableHeaders = [
  { key: "title", label: "Meeting Name" },
  { key: "start_date", label: "Meeting Date" },
  { key: "duration", label: "Duration (Hours)" },
  { key: "is_all_day", label: "All Day Event" },
  { key: "attendees", label: "Attendees" },
  { key: "event_category", label: "Category" },
  { key: "event_type", label: "Type" },
  { key: "recurrence_type", label: "Frequency" },
  { key: "locations", label: "Location" },
];

const CSVLinkButton = styled(Box, { label: "CSVLinkButton" })({
  height: "40px",
  marginLeft: 1,
  paddingTop: "6px",
  paddingBottom: "6px",
  borderRadius: "4px",
  textDecoration: "none",
  width: 91
});

const InteriorCSVButton = (
  { exportData }: { exportData: { [key: number]: LeaderEvent } | undefined }
) => <CSVLinkButton
  display="flex"
  flexDirection="row"
  alignItems="center"
>
  <Box paddingRight="12px" width={91} display="flex">
    <CabIcon sx={{ fontSize: 20, marginLeft: 1, marginRight: .5 }} Icon={IoDownloadOutline} />
    Export
  </Box>

</CSVLinkButton>;

export type ReconciliationProps = {
  myLeaders: Leader[];
  selectedLeader: Leader | null;
  onLeaderSelect: (leader: number | null) => void;
  selectPlaceholder?: string;
  execPickerDisabled?: boolean;
  onSearchInputChange: (e: string | null) => void;
  event_categories: { [key: number]: EventCategory };
  event_types: { [key: number]: EventType };
  consolidated_locations: { [key: number]: ConsolidatedLocation };
  rows: LeaderEvent[];
  executive_contacts: { [key: number]: ExecutiveContact };
  recurrence_types: EventAnalyticsState["recurrence_types"]
  analysis_inclusions: EventAnalyticsState["analysis_inclusions"]
  loading: boolean;
  handlePageChange: (newPage: number) => void;
  page: number;
  totalRows: number | undefined;
  handleSortModelChange: (sortModel: GridSortModel) => void;
  handleFilterModelChange: (model: GridFilterModel, details: GridCallbackDetails) => void;
  start: DateTime | undefined
  end: DateTime | undefined
  handleChangeDates: (startDate: DateTime | undefined, endDate: DateTime | undefined) => void
  user: User | null | undefined
  handleUpdateEvent: (event: Partial<LeaderEvent> & Pick<LeaderEvent, 'id'>) => void
  tz?: string
  maxDate?: DateTime;
  minDate?: DateTime;
  meta: AnalyticsEventsMeta;
  resyncFetched: boolean;
  exportData: { 
    data: { [key: number]: LeaderEvent }, meta: { contacts: { [key: number]: ExecutiveContact } }
  } | undefined;
  fetchAnalyticsReconciliationExport: () => Promise<boolean>
};

const Reconciliation = ({
  myLeaders,
  selectedLeader,
  onLeaderSelect,
  selectPlaceholder,
  execPickerDisabled,
  onSearchInputChange,
  event_categories,
  event_types,
  consolidated_locations,
  rows,
  executive_contacts,
  loading,
  handlePageChange,
  page,
  totalRows,
  handleSortModelChange,
  handleFilterModelChange,
  start,
  end,
  handleChangeDates,
  user,
  handleUpdateEvent,
  tz,
  maxDate,
  minDate,
  recurrence_types,
  exportData,
  fetchAnalyticsReconciliationExport,
  meta,
  analysis_inclusions,
  resyncFetched,
}: ReconciliationProps) => {

  const [searchInput, setSearchInput] = useState("");
  const [dataRequestOpen, setDataRequestOpen] = useState(false);
  const [editLabelsModelOpen, setEditLabelsModalOpen] = useState(false);
  const isXs = useMediaQuery('(max-width: 450px)');
  const [csvData, setCSVData] = useState<{
    title: string;
    start_date: string;
    duration: number;
    is_all_day: string;
    attendees: string;
    event_category: string | null;
    event_type: string | null;
    recurrence_type: string | null;
    locations: string | null
  }[]>([]);


  const handleLeaderSelect = (leaderId: number | null | undefined) => {
    onLeaderSelect(leaderId || null);
  };


  const onChangeHandleSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.currentTarget.value);
    onSearchInputChange(e.currentTarget.value);
  };

  const cabExecOptions = useMemo(() => myLeaders.map(leader => {
    return {
      value: leader.id,
      label: `${leader.first_name} ${leader.last_name}`,
      icon: <CabAvatar
        src={getLeaderIconSrc(leader)}
        color={leader.color}
        name={`${leader.first_name}
        ${leader.last_name}`}
        size="small"
      />
    };
  }), [myLeaders]);

  const fetchExportData = () => {
    setDataRequestOpen(true);
    fetchAnalyticsReconciliationExport();
  };

  const locMap: {[key: string]: string} = useMemo(() => ({
    "None": "No Location",
    "other": "Other",
    "phone": "Phone",
    "no_location": "",
    "virtual_conference": "Web Conference",
    "in_person": "In-Person",
  }), []);

  useEffect(() => {
    setCSVData([]);
  }, [rows]);

  useEffect(() => {
    if (exportData) {
      setCSVData(Object.values(exportData.data).map(row => ({
        title: row.title,
        start_date: row.start_date,
        duration: durationValueGetter(row) / 60,
        is_all_day: row.is_all_day ? "Yes" : "No",
        attendees: (row.attendees ? row.attendees.map(
          attendee => exportData.meta.contacts[attendee] ? exportData.meta.contacts[attendee].primary_email ?
            exportData.meta.contacts[attendee].primary_email : exportData.meta.contacts[attendee].primary_email_hashed
            : "") : []).join(" "),
        event_category: row.event_category ? event_categories[row.event_category]?.name ?? null : null,
        event_type: row.event_type ? event_types[row.event_type]?.name ?? null : null,
        recurrence_type: row.recurrence_type ? recurrence_types[row.recurrence_type]?.name ?? null : null,
        locations: row.consolidated_location ?
          consolidated_locations[row.consolidated_location]?.name.split(",").join(" ") ? 
            locMap[consolidated_locations[row.consolidated_location]?.name.split(",").join(" ")] : null : null,
      })).sort((a, b) => b.start_date.localeCompare(a.start_date)));
    }
  }, [exportData, event_categories, event_types, consolidated_locations, recurrence_types, locMap]);

  const mostRecentQueuedDate = meta.most_recent_queued_log_date ?
    DateTime.fromISO(meta.most_recent_queued_log_date) :
    null;

  return (
    <Box paddingLeft={2} paddingRight={2} display="flex" flex={1} flexDirection="column" marginBottom={2}>
      <Box alignItems="end">
        {!isXs && (
          <Box display='flex' justifyContent='space-between' gap={2} marginTop={2}>
            <Typography sx={{ color: colors.black600 }}>
              Browse past meetings and ensure tags are up to date for accurate reporting
            </Typography>
            {mostRecentQueuedDate &&
              <Typography sx={{color: colors.black600}} marginLeft={2} visibility={{ xs: 'hidden', md: "visible" }}>
                {(mostRecentQueuedDate && !resyncFetched) ? 
                  "Last Updated: " + mostRecentQueuedDate?.toFormat("LLL dd, yyyy h:mm a") : "sync request sent..."}
              </Typography>
            }
          </Box>
        )}
      </Box>
      <Box
        display="flex" flexDirection={{ sm: 'row', xs: 'column' }} flexWrap="wrap" marginBottom={1} marginTop={1}
        alignItems={{ sm: "end" }} gap={2}
      >
        <Box>
          <Typography>Show history for</Typography>
          <CabExecPicker<number>
            value={selectedLeader?.id || null}
            options={cabExecOptions}
            onChange={v => handleLeaderSelect(v)}
            placeholder={selectPlaceholder}
            disabled={execPickerDisabled}
            sx={{ maxWidth: '100%', "& .select-value-label": {fontWeight: "bold", fontSize: 20} }}
          />
        </Box>
        <Box display='flex' flexDirection={{ sm: 'row', xs: 'column' }} gap={2}>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <ReconciliationDatePicker
              start={start}
              end={end}
              handleChangeDates={handleChangeDates}
              tz={tz}
              maxDate={maxDate}
              minDate={minDate}
            />
          </Box>
          <CabInput
            placeholder="Search..."
            fullWidth
            size="small"
            sx={{minWidth: 200, maxWidth: 350}}
            value={searchInput}
            onChange={onChangeHandleSearchInput}
          />
        </Box>
      </Box>
      <Box position="relative" height="100%">
        <ReconciliationGrid
          fetchExportData={fetchExportData}
          event_categories={event_categories}
          event_types={event_types}
          consolidated_locations={consolidated_locations}
          rows={rows}
          executive_contacts={executive_contacts}
          recurrence_types={recurrence_types}
          analysis_inclusions={analysis_inclusions}
          loading={loading}
          handlePageChange={handlePageChange}
          page={page}
          totalRows={totalRows}
          handleSortModelChange={handleSortModelChange}
          handleFilterModelChange={handleFilterModelChange}
          user={user}
          handleUpdateEvent={handleUpdateEvent}
          handleEditLabelModal={() => setEditLabelsModalOpen(true)}
          sx={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
        />
      </Box>
      <CabModal
        open={dataRequestOpen}
        onClose={() => setDataRequestOpen(false)}
        title="Reconciliation Export Data"
        closeIcon={true}
      >
        {csvData.length > 0 ?
          <Box display='flex' flexDirection="column" alignItems="center">
            <Typography variant="subtitle2" sx={{ marginBottom: 2, marginTop: 1, width: "100%", textAlign: "center" }}>
              Your export is ready!
            </Typography>
            <CSVLink
              data={csvData}
              headers={tableHeaders}
              style={{ textDecoration: "none", marginLeft: 8, width: 91 }}
              filename={`event-reconciliation-${DateTime.now().setZone(tz).toISO()}.csv`}
              onClick={() => setDataRequestOpen(false)}
            >
              <InteriorCSVButton exportData={exportData} />
            </CSVLink>
          </Box>
          :
          <>
            <Typography sx={{ marginBottom: 1, width: "100%", textAlign: "center" }}>
              Your export request is in progress. Please keep this window open.
            </Typography>
            <CabSpinner scale={2} />
          </>
        }
      </CabModal>
      <CabModal
        open={editLabelsModelOpen}
        closeIcon
        onClose={() => setEditLabelsModalOpen(false)}
        sx={{ ".MuiPaper-root": { width: "100%" } }}
        title="Calendar Analytics"
      >
        <AnalyticsTab />
      </CabModal>
    </Box>
  );
};

export default Reconciliation;